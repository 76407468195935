.navbar {
    align-items: center;
    background-color: white;
    display: flex;
    height: var(--navbar-h);
    padding-left: var(--container-padding);
    position: fixed;
    top: 0;
    transition: transform .2s ease-out;
    width: 100%;
    z-index: 20;
}

.logo {
    margin-right: auto;
}

.logo,
.logo img {
    width: 130px;
}

.visible {
    transform: translateY(0px);
}

.hidden {
    transform: translateY(calc(-1 * var(--navbar-h)));
}

@media screen and (min-width: 400px) {
    .logo,
    .logo img {
        width: auto;
    }
}

@media screen and (min-width: 1461px) {
    /* showHamburger breakpoint */
    .navbar > a:not([data-color]) {
        display: inline-block;
        line-height: 1.5;
        max-width: 132px;
        text-align: center;
    }
}
