/*!
 * Hamburgers lib
 * https://github.com/jonsuh/hamburgers
 */

.hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.active:hover {
    opacity: 0.7;
}

.hamburger.active .inner,
.hamburger.active .inner::before,
.hamburger.active .inner::after {
    background-color: var(--teal);
}

.box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.inner,
.inner::before,
.inner::after {
    width: 40px;
    height: 3px;
    background-color: var(--teal);
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.inner::before,
.inner::after {
    content: "";
    display: block;
}

.inner::before {
    top: -10px;
}

.inner::after {
    bottom: -10px;
}


/* Spin */

.spin .inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.spin .inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.spin .inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.spin.active .inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.spin.active .inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.spin.active .inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.menu {
    background: white;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow-y: auto;
    padding-bottom: 20px;
    position: fixed;
    text-align: center;
    top: var(--navbar-h);
    width: 100%;
}

.menu > a {
    padding: 20px 0;
}