.mobile {
    border-bottom: 1px solid var(--input-border-c);
    display: flex;
    flex-direction: column;
}

.menu {
    border: 1px solid var(--input-border-c);
    padding: 60px 30px;
    position: absolute;
    right: 0;
    top: var(--navbar-h);
    z-index: 55;
}

.mobile,
.menu {
    animation: fadeIn .3s ease-out;
    background: white;
}

.mobile > a {
    padding: 20px 0;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}