.footer {
    background-color: var(--dark-blue);
    padding-top: 36px;
}

.grid {
    display: block;
    text-align: center;
}

.copyright {
    background-color: var(--teal);
    margin-top: 36px;
    padding: 18px 0;
}

.footer h3,
.footer p,
.footer a,
.copyright span {
    color: white;
}

.footer h3 {
    margin-top: 40px;
}

.footer p,
.footer a {
    font-size: 15.6rem;
    opacity: .9;
}

.footer a:hover {
    opacity: 1;
    text-decoration: underline;
}

.social_media {
    display: flex;
    justify-content: center;
    margin: 26px 0;
}

.social_media > a {
    margin-right: 20px;
}

@media screen and (min-width: 921px) {
    .grid {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        text-align: left;
    }

    .footer h3 {
        margin-top: 2px;
    }

    .social_media {
        justify-content: flex-start;
        margin-top: 10px;
    }
}