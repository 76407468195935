.badge {
    align-items: center;
    background: var(--red);
    border-radius: 15px;
    color: #fff;
    display: inline-flex;
    font-size: 10rem;
    font-weight: 100;
    height: 18px;
    justify-content: center;
    margin: 0 6px;
    width: 18px;
    vertical-align: middle;
}