.left,
.right {
    bottom: -12px;
    position: absolute;
}

.left i,
.right i {
    color: var(--dark-blue);
    font-size: 14px;
    left: 2px;
    position: absolute;
    bottom: -14px;
}

.left {
    left: -11px;
}

.right {
    right: -12px;
}